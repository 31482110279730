.modal {
    @apply absolute mt-9 rounded shadow-2xl z-40;
}

.bg {
    @apply rounded-md;
}

.bgGray {
    @apply bg-offwhite-dark dark:bg-offblack-normal;
}


:global(.dark) .bgGray {
    @apply bg-offblack-normal;
}
