.root {
    @apply
    px-3 py-2 whitespace-nowrap
    text-center inline-flex justify-center items-center
    border-0 border-transparent shadow-sm rounded-xl
    transition duration-200 ease-in-out transform-gpu active:scale-95
    cursor-pointer;

    /* that is "copy" */
    font-family: inherit;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    letter-spacing: 0em;
}

.root:hover {
}

.root:focus {
    @apply outline-none;
}

.root:disabled {
    @apply cursor-not-allowed;
}

.loading {
    @apply cursor-not-allowed;
}

.fullWidth {
    @apply w-full;
}

/*
  variant: black
 */
.black {
    @apply text-white bg-black
    hover:bg-opacity-80;
}


:global(.dark) .black {
    @apply text-black bg-white hover:bg-offblack-normal hover:text-white;
}

.black:disabled {
    @apply bg-opacity-50;
}

/* variant: white*/
.white {
    @apply text-black bg-white
    hover:bg-offblack-normal hover:text-white;
}

.white.toggled {
    @apply text-white bg-offblack-normal
    hover:bg-white hover:text-black;
}

:global(.dark) .white {
    @apply text-white bg-black hover:bg-offwhite-normal hover:text-black;
}

:global(.dark) .white.toggled {
    @apply text-black bg-offwhite-normal hover:bg-black hover:text-white;
}

.white:disabled {
    @apply bg-opacity-50 text-opacity-50;
}

/* variant: gray*/
.gray {
    @apply text-black bg-offwhite-normal hover:bg-offwhite-dark;
}

:global(.dark) .gray {
    @apply text-white bg-offblack-normal hover:bg-offblack-light;
}

.gray.toggled {
    @apply bg-offwhite-dark hover:bg-offwhite-normal;
}

/*:global(.dark) .gray {*/
/*  @apply text-white bg-black hover:bg-offwhite-normal hover:text-black;*/
/*}*/

/*:global(.dark) .gray.toggled {*/
/*  @apply text-black bg-offwhite-normal hover:bg-black hover:text-white;*/
/*}*/

.gray:disabled {
    @apply bg-opacity-50 text-opacity-50;
}
