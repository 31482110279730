.toggle {
    @apply w-8 rounded-full h-5 bg-offwhite-normal dark:bg-offblack-normal cursor-pointer flex items-center relative select-none;
}

.toggleSelected {
    @apply bg-offblack-normal;
}

.dot {
    @apply h-4 w-4 bg-white dark:bg-black rounded-full transform transition-transform translate-x-0.5;
}

.dotSelected {
    @apply translate-x-3.5;
}
