.card {
    @apply p-5 rounded space-y-5 flex-1;
}

.bgGray {
    @apply bg-offwhite-normal hover:bg-offwhite-dark ;
}

:global(.dark) .bgGray {
    @apply bg-offblack-normal hover:bg-offblack-light;
}

.bgGreen {
    @apply bg-green-light dark:bg-green-light;
}

.bgYellow {
    @apply bg-yellow-light hover:bg-yellow-normal text-black;
}

.bgOrange {
    @apply bg-orange-light hover:bg-orange-normal text-black;
}
