.root {

}
/* big */
.big {
    font-size: 198px;
    line-height: 188px;
    font-weight: normal;
    font-style: normal;
    text-transform: uppercase;
}

/* Headline */
.h1 {
    font-size: 96px;
    line-height: 96px;
    font-style: normal;
    text-transform: uppercase;
}

/* Headline 2 */
.h2 {
    font-size: 72px;
    line-height: 79px;
    font-style: normal;
    text-transform: uppercase;
}

/* Headline 3 */
.h3 {
    font-size: 40px;
    line-height: 44px;
    font-style: normal;
    text-transform: uppercase;
}

/* Headline addition */
.ha {
    font-size: 18px;
    line-height: 92.79%;
    font-style: normal;
    text-transform: uppercase;
}

/* link */
.link {
    font-size: 18px;
    line-height: 124.7%;
    text-transform: uppercase;

}

/* menu */
.menu {
    font-size: 14px;
    line-height: 17px;
    text-transform: uppercase;
}

/* copy */
.body {
    font-size: 14px;
    line-height: 110.2%;
}

/*for animated underlines*/
.underline-h-1,
.underline-h-2,
.underline-h-4 {
    display: inline-block;
    position: relative;
    @apply cursor-pointer;
}

.underline-h-1:after {
    height: 1px;
}

.underline-h-2:after {
    height: 2px;
}

.underline-h-4:after {
    height: 4px;
}

.underline-h-1:after,
.underline-h-2:after,
.underline-h-4:after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    bottom: 0;
    left: 0;
    background: currentColor;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
}

.underline-h-1:hover:after,
.underline-h-2:hover:after,
.underline-h-4:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
}
