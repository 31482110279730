/*.root {}*/

.container {
  @apply grid grid-flow-row grid-cols-1 gap-2 overflow-auto;
}

.header {
  @apply px-0;
  align-items: center;
  display: grid;
  grid-template-columns: 64px 40px 1fr 1fr 1fr 1fr 0.5fr 0.5fr 0.5fr 0.5fr 60px;
  gap: 1em 1em;
}

.item {
  @apply pl-0 rounded h-16 items-center text-black;
  align-items: center;
  display: grid;
  grid-template-columns: 64px 40px 1fr 1fr 1fr 1fr 0.5fr 0.5fr 0.5fr 0.5fr 60px;
  gap: 1em 1em;
}

.subItem {
  @apply pl-0 rounded h-11 items-center text-black;
  align-items: center;
  display: grid;
  grid-template-columns: 64px 40px 1fr 1fr 1fr 1fr 0.5fr 0.5fr 0.5fr 0.5fr 60px;
  gap: 1em 1em;
}

.default {
  @apply bg-offwhite-normal hover:bg-offwhite-dark dark:bg-offblack-normal
}

.new {
 @apply bg-yellow-light hover:bg-yellow-normal;
}

.postponed {
  @apply bg-orange-light hover:bg-orange-normal;
}

.scraping-img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.scraping-img-container {
  position: relative;
  @apply overflow-hidden;
}

.scraping-img-container::after {
  content: '';
  display: block;
  padding-bottom: 100%;
}
