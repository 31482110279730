.root {
  @apply block shadow-sm rounded-xl
    border-none outline-none
    w-72 h-8
    text-sm
    placeholder-black dark:placeholder-white placeholder-opacity-40;
}

.root:focus {
  @apply ring-0 outline-none;
}

.number {
  @apply w-24 text-right;
}

.number:disabled {
  @apply opacity-50 cursor-not-allowed;
}

.checkbox {
  @apply focus:ring-gray-700 h-4 w-4 text-gray-700 border-black rounded;
}

.radio {
  @apply focus:ring-gray-700 h-4 w-4 text-gray-700 border-black;
}

.gray {
  @apply bg-offwhite-normal hover:bg-offwhite-dark placeholder-black  placeholder-opacity-50;
}

:global(.dark) .gray {
  @apply bg-offblack-normal hover:bg-offblack-light placeholder-white  placeholder-opacity-50;
}
